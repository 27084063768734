<template>
  <Popover
    class="relative sticky top-0 z-50 bg-white site-header isolate drop-shadow"
    :class="{ scrolled: isScrolled }"
    v-slot="{ open, close }"
  >
    <div
      class="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 xl:px-0 xl:justify-start xl:space-x-10"
    >
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <NuxtLink href="/">
          <span class="sr-only">Workflow</span>
          <img
            class="w-64 h-auto"
            :style="{
              width: isScrolled && isMobile ? '192px !important' : '',
            }"
            src="/img/bcn-logo-horiz-r.jpg"
            alt="Boulder Creek Neighborhoods logo"
          />
        </NuxtLink>
      </div>
      <div class="-my-2 -mr-2 xl:hidden">
        <PopoverButton
          class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bc-blue"
        >
          <span class="sr-only">Open menu</span>
          <MenuIcon class="w-6 h-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <PopoverGroup as="nav" class="hidden desktop-nav xl:flex space-x-10">
        <ul class="xl:flex space-x-10">
          <li
            v-for="item in menu"
            :id="'menu-item-' + item.databaseId"
            :key="item.id"
            :class="item.cssClasses.join(' ')"
          >
            <Popover
              v-if="item.children.length"
              class="relative"
              v-slot="{ open }"
            >
              <PopoverButton
                @mouseover="(e) => hoverPopover(e, open)"
                @mouseleave="closePopover(close)"
                :class="[
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group bg-white rounded-md inline-flex items-center font-medium theme-text-xl-serif hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bc-blue/0',
                ]"
              >
                <span>{{ item.label }}</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <div>
                  <PopoverPanel
                    @mouseover.prevent="popoverHover = true"
                    @mouseleave.prevent="closePopover(close)"
                    class="absolute z-10 w-screen max-w-sm mt-3 -ml-4 submenu-container transform lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  >
                    <div
                      class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                      <div class="relative px-4 pt-4 bg-white inner">
                        <ul
                          v-for="item in item.children"
                          :key="item.id"
                          :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-gray-500'
                          "
                        >
                          <li
                            :id="'menu-item-' + item.databaseId"
                            class="p-2 underline uppercase"
                          >
                            {{ item.label }}
                          </li>
                          <li>
                            <ul>
                              <li
                                v-for="item in item.children"
                                :key="item.id"
                                :id="'menu-item-' + item.databaseId"
                                :class="
                                  item.cssClasses.join(' ') +
                                  ' theme-text-xl-serif leading-tight py-[2.5px] text-gray-900'
                                "
                              >
                                <PopoverButton
                                  as="a"
                                  :href="item.uri"
                                  class="block p-2 hover:bg-gray-100"
                                  :target="item.target ? item.target : '_self'"
                                >
                                  <div>
                                    <span class="block mb-1">
                                      {{ item.label }}
                                      <span
                                        v-if="
                                          item.connectedNode?.node?.neighborhood
                                            ?.city.length
                                        "
                                      >
                                        in
                                        {{
                                          item.connectedNode?.node?.neighborhood
                                            ?.city
                                        }}
                                      </span>
                                    </span>
                                    <span
                                      v-if="item.description"
                                      class="block !text-sm font-normal text-gray-500 theme-text-sm"
                                    >
                                      {{ item.description }}
                                    </span>
                                    <span
                                      v-if="
                                        item.connectedNode?.node?.__typename ===
                                        'Neighborhood'
                                      "
                                      class="block !text-sm font-normal text-gray-500 theme-text-sm"
                                    >
                                      {{
                                        getNeighborhoodNavSubtitle(
                                          item.connectedNode?.node?.types,
                                          getRoundedPrice(
                                            item.connectedNode?.node?.basePrice,
                                          ),
                                          item.connectedNode?.node?.neighborhood
                                            ?.status,
                                          true,
                                        )
                                      }}
                                    </span>
                                  </div>
                                </PopoverButton>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </PopoverPanel>
                </div>
              </transition>
            </Popover>

            <NuxtLink
              v-else
              :href="item.uri"
              class="text-base font-medium text-gray-500 theme-text-xl-serif hover:text-gray-900"
            >
              {{ item.label }}
            </NuxtLink>
          </li>
        </ul>
      </PopoverGroup>
      <div class="items-center justify-end hidden xl:flex xl:flex-1 lg:w-0">
        <NuxtLink
          href="/contact-us/homeowner-resources/"
          class="text-base font-medium text-bc-dark-blue whitespace-nowrap hover:text-bc-dark-blue/80"
        >
          Warranty Info
        </NuxtLink>
        <NuxtLink
          v-if="true"
          to="/my-favorites/"
          class="inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white border border-transparent bg-bc-purple whitespace-nowrap rounded-md shadow-sm hover:bg-bc-purple/80"
        >
          <HeartIcon
            class="w-5 h-5 mr-2 group-hover:text-gray-500 fill-heart-red"
            aria-hidden="true"
          />
          Favorites
        </NuxtLink>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div>
        <PopoverPanel
          focus
          class="absolute inset-x-0 top-0 z-50 p-2 transition transform origin-top-right xl:hidden"
        >
          <div
            class="rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 max-h-[80vh] overflow-y-scroll touch-auto"
          >
            <div class="px-5 pt-5 pb-6">
              <div class="flex items-center justify-between">
                <div>
                  <PopoverButton as="a" href="/">
                    <img
                      class="w-64 h-auto"
                      src="/img/BCN_Logo_Color-horiz.png"
                      alt="Workflow"
                    />
                  </PopoverButton>
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-bc-blue"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="w-6 h-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="flex flex-col flex-grow mt-5">
                <nav class="flex-1 px-2 bg-white mobile-nav space-y-1">
                  <template
                    v-for="item in menu"
                    :key="item.id"
                    :id="'mobile-menu-item-' + item.databaseId"
                    :class="item.cssClasses.join(' ')"
                  >
                    <Disclosure
                      as="div"
                      v-if="item.children?.length"
                      class="space-y-1"
                      v-slot="{ open }"
                    >
                      <div v-if="open">
                        <DisclosureButton
                          as="a"
                          :href="item.uri"
                          :target="item.target ? item.target : '_self'"
                          :class="[
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-bc-blue',
                          ]"
                        >
                          <svg
                            :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                          <span
                            class="ml-2 text-xs italic text-gray-400 underline"
                            >(visit {{ item.label }})</span
                          >
                        </DisclosureButton>
                      </div>
                      <div v-else>
                        <DisclosureButton
                          :class="[
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-bc-blue',
                          ]"
                        >
                          <svg
                            :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                        </DisclosureButton>
                      </div>
                      <DisclosurePanel class="space-y-1 menu-parent-container">
                        <ul
                          v-for="item in item.children"
                          :key="item.id"
                          :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-gray-500'
                          "
                        >
                          <li class="pl-10 text-sm underline uppercase">
                            {{ item.label }}
                          </li>
                          <li
                            v-for="item in item.children"
                            :key="item.id"
                            :id="'menu-item-' + item.databaseId"
                            :class="
                              item.cssClasses.join(' ') +
                              ' theme-text-xl-serif text-black'
                            "
                          >
                            <DisclosureButton
                              as="a"
                              :href="item.uri"
                              :target="item.target ? item.target : '_self'"
                              :class="
                                item.cssClasses.join(' ') + ' inline-block w-full py-2 pl-14 pr-2 text-lg font-medium text-gray-600 group rounded-md hover:text-gray-900 hover:bg-gray-50'
                              "
                            >
                              {{ item.label }}
                              <span
                                v-if="
                                  item.connectedNode?.node?.neighborhood?.city
                                    .length
                                "
                              >
                                in
                                {{
                                  item.connectedNode?.node?.neighborhood?.city
                                }}
                              </span>
                            </DisclosureButton>
                          </li>
                        </ul>
                      </DisclosurePanel>
                    </Disclosure>

                    <div v-else>
                      <NuxtLink
                        :href="item.uri"
                        :target="item.target ? item.target : '_self'"
                        :class="[
                          item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group w-full flex items-center pl-7 pr-2 py-2 text-lg font-medium rounded-md',
                        ]"
                      >
                        {{ item.label }}
                      </NuxtLink>
                    </div>
                  </template>
                </nav>
              </div>
            </div>
            <div v-if="true" class="px-5 py-6">
              <div class="mt-6">
                <NuxtLink
                  to="/my-favorites/"
                  class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent bg-bc-purple rounded-md shadow-sm hover:bg-bc-purple/80"
                >
                  <HeartIcon
                    class="w-5 h-5 mr-2 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  &#32;Favorites
                </NuxtLink>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </transition>

    <MoleculesBreadcrumbNav />
  </Popover>
</template>

<style lang="postcss">
/*
.our-homes ul:nth-child(1) ul li:nth-child(even) {
  @apply bg-gray-50;
}
*/

.our-homes ul:nth-child(2) {
  @apply bg-gray-50 -mx-4 px-4 pt-4;
}

.our-homes ul:nth-child(2) li ul li {
  @apply text-base font-ubuntu font-medium;
}

.our-homes ul:nth-child(2) li ul li:last-of-type {
  @apply text-bc-blue pb-4;
}

.desktop-nav .icon-easyhouse a,
.desktop-nav .icon-wee-cottage a,
.desktop-nav .icon-limited-edition a,
.desktop-nav .icon-new-beginnings a {
  @apply grid grid-cols-[2.5rem_1fr];
}

.desktop-nav .icon-easyhouse a::before,
.desktop-nav .icon-wee-cottage a::before,
.desktop-nav .icon-limited-edition a::before,
.desktop-nav .icon-new-beginnings a::before {
  @apply block bg-no-repeat bg-contain bg-center w-10 h-10 overflow-hidden;
  content: "";
}

.desktop-nav .icon-easyhouse a::before {
  background-image: url("/img/easyhouse-icon.svg");
}

.desktop-nav .icon-wee-cottage a::before {
  background-image: url("/img/wee-cottage-icon.svg");
}

.desktop-nav .icon-limited-edition a::before {
  background-image: url("/img/limited_edition-icon.svg");
}

.desktop-nav .icon-new-beginnings a::before {
  background-image: url("/img/new-beginnings-icon--nav.svg");
}

.neighborhoods .submenu-container {
  @apply max-w-[930px];
}

.neighborhoods .inner {
  @apply grid grid-cols-3;
}

.neighborhoods .inner ul:nth-child(4) {
  @apply col-span-3 bg-gray-50 -mx-4 px-4 mt-4;
}

.neighborhoods .inner ul:nth-child(4) li ul li {
  @apply text-base font-ubuntu font-medium text-bc-blue pb-4;
}

.about-us .submenu-container {
  @apply max-w-4xl;
}

.about-us .inner {
  @apply grid grid-cols-3;
}

.about-us ul ul li a {
  @apply py-[2px];
}

.about-us ul ul li:last-of-type {
  @apply pb-4;
}

.contact-us ul ul li a {
  @apply py-[2px];
}

.contact-us .inner ul:nth-child(1) {
  @apply mb-4;
}

.contact-us .inner ul:nth-child(2) {
  @apply bg-gray-50 -mx-4 px-4 pt-4 pb-4;
}

.desktop-nav .icon-phone a,
.desktop-nav .icon-home a {
  @apply flex items-baseline font-ubuntu font-medium text-base;
}

.desktop-nav .icon-phone a::before,
.desktop-nav .icon-home a::before {
  @apply text-bc-blue block w-5 h-5 bg-no-repeat bg-contain relative top-1 mr-1;
  content: "";
}

.desktop-nav .icon-phone a::before {
  background-image: url("/img/phone-icon.svg");
}

.desktop-nav .icon-home a::before {
  background-image: url("/img/home-icon.svg");
}

.mobile-nav ul {
  @apply !mb-4;
}

.mobile-nav ul:last-of-type {
  @apply !mb-8;
}

.feat-blue {
  @apply text-bc-blue;

  a {
    @apply text-bc-blue;
  }
}

.featured-neighborhood {
  @apply relative xl:pt-[275px] pt-0 hover:bg-gray-100; 

  span:nth-child(1) {
    @apply xl:text-bc-blue;
  }

  &::before {
    content:'';
    display:block;
    background-image: url("/img/feat-neighborhood-img.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 255px;
    height:150px;
    margin-left: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    position:absolute;
    top:0;

    @media screen and (max-width:1280px) {
      @apply hidden;
    }
  }

  a {
    @apply xl:absolute block top-0 w-full xl:pt-[175px] pt-2 hover:bg-transparent text-bc-blue;
  }

  span:nth-child(2) {
    @apply xl:text-[#B8801C] relative xl:ml-5 xl:font-bold;
  
    &::before {
      content:'';
      display: block;
      position: absolute;
      background-image: url("/img/feat-neighborhood-home-icon.svg");
      height: 16px;
      width: 16px;
      left:-20px;
      top: 1px;
    
      @media screen and (max-width:1280px) {
        @apply hidden;
      }
    }
  }
}

/* Mobile re-ordering for featured neighborhood */
.menu-parent-container {
  @apply grid grid-cols-1;
  .featured-neighborhood-parent {
    @apply order-1;
  }

  ul {
    @apply order-2;
  }
}
</style>

<script setup lang="ts">
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline/index.js";
import { HeartIcon } from "@heroicons/vue/solid/index.js";
import { ChevronDownIcon } from "@heroicons/vue/solid/index.js";

const props = defineProps({
  menu: Array,
});

const popoverHover = ref(false);
const popoverTimeout = ref();

const hoverPopover = (e: any, open: boolean): void => {
  popoverHover.value = true;
  if (!open) {
    if (e.target && ["BUTTON", "SPAN", "SVG"].includes(e.target.nodeName)) {
      e.target.closest("button").click();
    }
  }
};

const closePopover = (close: any): void => {
  popoverHover.value = false;
  if (popoverTimeout.value) clearTimeout(popoverTimeout.value);
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) {
      close();
    }
  }, 100);
};

const isScrolled = ref(false);
const isMobile = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 200;
};

const handleResize = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  // Now that we're client-side, we can safely check the window's properties
  isMobile.value = window.innerWidth <= 1024;

  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize);
});
</script>
